.deckBuilder .container {
  max-width: 594px;
  margin: auto;
}

.cardInfo ul li {
  list-style: none;
  margin: 0 20px;
  text-align: center;
}

.cardInfo ul {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  justify-content: center;
  margin-top: 30px;
}

.cardInfo ul li img {
  width: 40px;
  display: block;
  margin-top: 10px;
}

.cardInfo {
  text-align: center;
  margin-top: 40px;
}

.deckBuilderCards {
  grid-gap: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.deckBuilderCards img {
  cursor: pointer;
}

.deckBuilderBtns {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  max-width: 246px;
  left: 0;
  right: 0;
  margin: auto;
}

.deckBuilder {
  padding-bottom: 60px;
}

.deckBuilderCards img.selected {
  border: 4px solid #f0ebc0;
  border-bottom: 10px solid #f0ebc0;
}

.cardIconsInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 460px;
  margin: auto;
  @media (max-width: 440px) {
    flex-flow: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
}

.cardIconsInfo ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.cardIconsInfo ul li img {
  width: 36px;
  margin: 0 5px;
}
