.mainLayout {
	background: url("../../assets/images/backgrounds/mainBg.png"); //linear-gradient(180deg, rgba(38, 39, 40, 0.6), rgba(34, 34, 35, 0.6)), 
	
	background-position: 0 0;
    min-height: 100vh;
	padding: 1rem;
	padding-bottom: 10rem;
	padding-top: 50px;
	@media (max-width: 500px) {
		background-size: 100% 100%;
	}
}