.gameBoard {
    width: 365px;
    margin: auto;
    padding: 0 10px;
	position: relative;
	@media (max-width: 390px) {
		width: 300px;
	}
}

.gameBoardContaienr {
	.logo {
		margin-top: 0;
		img {
			max-width: 80px!important;
		}
	}
}

.gameBoardContainer .logo img {
    max-width: 130px;
}