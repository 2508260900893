.modalContainer {
	&.howToPlay {
		padding: 50px 40px;
		text-align: center;
		p.leader-board-title {
			text-align: center;
			color: white;
			margin-bottom: 2rem;
		}
		
		.video {
			margin-bottom: 2rem;
			video {
				width: 100%;
			}
		}
		.primary-button {
			margin: auto;
		}
	}
}

