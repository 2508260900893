.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: rgb(74 74 74 / 75%) !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 10000000;
}

.modalContainer {
  padding: 50px;
  background-image: url("../../../assets/images/cardshop/cardModalBg.png");
  background-size: 100% 100%;
  border: none;
  background-color: transparent;
  position: relative;
}
