.allowanceAdd {
  width: 397px;
  margin: 18px auto 20px;
  border: 3px solid #717070;
  padding: 0 5px 0px 15px;
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  background: #1a1a1a;
  z-index: 10;
  color: #ababac;
  @media (max-width: 430px) {
    width: 310px;
  }
  label {
    display: block;
    letter-spacing: 0.92px;
    color: #ababac;
    font-size: 12px;
    line-height: 22px;
  }
  button {
    position: absolute;
    right: -2px;
    top: -7px;
    min-height: 50px;
  }
}
.backBtn {
  text-align: center;
  margin-top: 3rem;
}
