.cardShopDescription p {
  font-size: 22px;
  color: #ababac;
  text-align: center;
  b {
    color: #f0ebc0;
  }
  .error {
    color: red;
  }
}
.cardShopContainer {
  max-width: 600px;
  margin: auto;
  .cardsList {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: 390px;
    margin: auto;
    .cardBox {
      text-align: center;
      img {
        max-width: 100%;
        width: 147px;
      }
      img:first-child {
        max-width: 100%;
        width: 147px;
        margin-bottom: 10px;
      }
      .buyButtons img {
        width: 20px;
        margin-right: 6px;
      }
      &:last-child img {
        width: 200px;
        margin-top: -15px;
      }
    }
  }
}

.qtyBox {
  display: flex;
  justify-content: space-between;
  background: #1a1a1a 0% 0% no-repeat padding-box;
  border: 3px solid #717070;
  align-items: center;
  letter-spacing: 1.23px;
  color: #717070;
  font-size: 16px;
  line-height: 22px;
  justify-content: center;
  height: 36px;
  position: relative;
}

.qtyBox .left {
  position: absolute;
  left: -4px;
  min-height: 36px;
  transform: translateY(-0);
}

.qtyBox .right {
  position: absolute;
  right: -4px;
  min-height: 36px;
  transform: translateY(-0);
}

.buyButtons img {
}

.buyButtons {
  display: flex;
  margin-top: 12px;
}

.qtyBox button {
  min-height: 37px;
  padding: 0;
  padding-bottom: 5px;
  padding-left: 7px;
  font-size: 37px;
  /* height: 36px; */
  line-height: 22px;
}

.buyButtons button {
  display: flex;
  height: 36px;
  align-items: center;
  padding: 5px 18px;
  min-height: 36px;
  letter-spacing: 1.23px;
  color: #717070;
  font-size: 16px;
  justify-content: end;
  padding-bottom: 9px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 390px) {
    max-width: 65px;
    padding: 5px 7px;
  }
}

.qtyBox .right:active {
  transform: translateY(4px);
}

.cardShopContainer .cardsList .cardBox:last-child .buyButtons img {
  margin-top: 0;
}

.cardShopContainer .cardIconsInfo {
  margin-top: 50px;
}
