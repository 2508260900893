.yourTrophies {
  border: 3px solid #82b5d9;
  position: relative;
  padding: 20px;
  max-width: 768px;
  margin: 5rem auto 0;
  .leader-board-content .userName {
    width: calc(100% - 150px);
  }
}

.trophy-content {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Adjust spacing between items */
  padding: 20px; /* Add some padding around the container */
}

.trophy-item {
  display: flex;
  justify-content: space-between; /* Align label and value */
  font-size: 16px;
  color: #fff; /* Assuming you want white text */
  background-color: #333; /* Background color for each item */
  padding: 10px;
  border-radius: 8px; /* Rounded corners */
}

.trophy-label {
  font-weight: bold;
  color: #f0e68c; /* Label color (light yellow, for example) */
}

.tournamentPlacements.trophies li {
  list-style: none;
  margin: 4px 0;
  width: 100%;
  font-size: 18px;
  letter-spacing: 0.02em;
}

.tournamentPlacements.trophies {
  padding: 0 20px;
}

.tournamentPlacements.trophies .title {
  text-shadow: 0px 4px 0px rgba(255, 255, 255, 0.3960784314);
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 30px;
  line-height: 30px;
}
