.rulesAndRisk h1 {
    letter-spacing: 3.31px;
    color: #FFFFFF;
    text-shadow: 0px 3px 0px #FFFFFF5A;
    font-size: 43px;
    line-height: 38px;
    margin-bottom: 38px;
    text-transform: uppercase;
}

.rulesAndRisk p {
    letter-spacing: 1.23px;
    color: #ABABAC;
    font-size: 16px;
    line-height: 22px;
}

.rulesAndRisk {
    max-width: 375px;
    margin: auto;
    text-align: center;
}

.rulesAndRisk .btn-groups {
    margin-top: 88px;
}

.btn-groups button {
    margin-bottom: 7px;
}