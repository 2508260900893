.primary-button-styles {
	text-transform: uppercase;
	letter-spacing: 0.03em;
	letter-spacing: 1.85px;
	font-size: 22px;
	min-height: 48px;
	border: 3px solid #717070;
	font-family: 'Pixel Millennium';
	cursor: pointer;
	padding: 0.75rem;
}

/********* Button hover effect ************/

.raise:hover,
.raise:focus {
	color: #ABABAC;
  /* transform: translateY(-0.25em); */
}

.raise {
	transform: translateY(-0.25em);
}

.raise:hover:after,
.raise:focus:after {
	background: #ABABAC;
  /* transform: translateY(-0.25em); */
}

/* .raise:before, .raise:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: 0px;
    background: #707070;
    left: 0;
    z-index: 10;
} */

.raise:hover:before, .raise:hover:before {
    background: #ABABAC;
}

.raise:active {
	  transform: translateY(0);

}

.raise:active::before {
    height: 0;
}


.primary-button-styles {
	text-transform: uppercase;
	letter-spacing: 0.03em;
	letter-spacing: 1.85px;
	font-size: 22px;
	min-height: 48px;
	border: 3px solid #717070;
    background: #1a1a1a;
    color: #707070;
}

.primary-button-styles:disabled {
	cursor: not-allowed;
	opacity: 0.5;
	border-color: #aaa;
	background: #333;
	color: #aaa;
  }

/************* Button border styles ***************/
span.topCornerLeftDots {
	&:after {
		content: "";
		position: absolute;
		width: 3px;
		height: 3px;
		background: #1a1a1a;
		top: -3px;
		left: -3px;
	}
	&:before {
		content: "";
		position: absolute;
		width: 3px;
		height: 3px;
		background: #717070;
		top: 0px;
		left: 0px;
	}
}


span.topCornerRightDots {
	&:after {
		content: "";
		position: absolute;
		width: 3px;
		height: 3px;
		background: #1a1a1a;
		top: -3px;
		right: -3px;
	}
	&:before {
		content: "";
		position: absolute;
		width: 3px;
		height: 3px;
		background: #717070;
		top: 0px;
		right: 0px;
	}
}


span.bottomCornerLeftDots {
	&:after {
		content: "";
		position: absolute;
		width: 3px;
		height: 3px;
		background: #1a1a1a;
		bottom: -3px;
		right: -3px;
	}
	&:before {
		content: "";
		position: absolute;
		width: 3px;
		height: 3px;
		background: #717070;
		bottom: 0px;
		right: 0px;
	}
}


span.bottomCorneRightDots {
	&:after {
		content: "";
		position: absolute;
		width: 3px;
		height: 3px;
		background: #1a1a1a;
		bottom: -3px;
		left: -3px;
	}
	&:before {
		content: "";
		position: absolute;
		width: 3px;
		height: 3px;
		background: #717070;
		bottom: 0px;
		left: 0px;
	}
}

span.extraShadow {
	position: absolute;
    width: 100%;
    height: 5px;
    bottom: 0px;
    background: #707070;
    left: 0;
    z-index: 10;
	&:after {
		content: "";
		position: absolute;
		width: 3px;
		height: 3px;
		background: #717070;
		top: -3px;
		left: 0px;
	}
	&:before {
		content: "";
		position: absolute;
		width: 3px;
		height: 3px;
		background: #717070;
		top: -3px;
		right: 0px;
	}
}


.primary-button-styles:hover span:before {
    background: #ABABAC;
}

.primary-button-styles:hover {
    border-color: #ABABAC;
}

.primary-button-styles:hover span.extraShadow:after {
    background: #ABABAC;
}

/*********** Primary button secondary color *******************/

.primary-button-styles.secondaryColor {
	border: 3px solid #B7B18A;
	background: #F0EBC0;
    color: #B7B18A;
}

.primary-button-styles.secondaryColor:hover {
    border: 3px solid #CBC6A3;
    color: #CBC6A3;
}

.primary-button-styles.secondaryColor:hover span.extraShadow {
    background: #CBC6A3;
}

.primary-button-styles.secondaryColor span.extraShadow {
	background: #B7B18A;
}

.primary-button-styles.secondaryColor span.topCornerLeftDots:before {
	background: #B7B18A;
}

.primary-button-styles.secondaryColor span.topCornerRightDots:before {
	background: #B7B18A;	
}

.primary-button-styles.secondaryColor span.BottomCornerRightDots:before {
	background: #B7B18A;	
}

.primary-button-styles.secondaryColor span.BottomCornerRightDots:before {
	background: #B7B18A;	
}

.primary-button-styles.secondaryColor span.extraShadow:before {
	background: #B7B18A;	
}

.primary-button-styles.secondaryColor span.extraShadow:after {
	background: #B7B18A;	
}
/********** end of primary button secondary color *************/


.primary-button-styles:hover span.extraShadow {
    background: #ABABAC;
}

.primary-button-styles:active span.extraShadow {
   display: none;
}
