.bodyImage img {
    max-height: 170px;
    margin: auto;
}

.bodyImage {
    text-align: center;
}

.gif {
    position: absolute;
    width: 100%;
    max-width: 260px;
    left: 50px;
}

.gif img {
    width: 100%;
}