.modalContainer.cardsMinted {
  padding: 50px 20px;
  @media (max-width: 380px) {
    max-width: 100% !important;
  }
  .images {
    display: flex;
    img {
      height: 300px;
    }
  }
  &.WIN {
    .btns .box {
      //   background: #b1d5a0;
      left: -80px;
      img {
        width: 305px;
        height: auto;
      }
    }
    p {
      span {
        color: #b1d5a0;
      }
    }
  }
  &.LOSE {
    .btns .box {
      left: -80px;
      img {
        width: 305px;
        height: auto;
      }
    }
    p {
      span {
        color: #c3736c;
      }
    }
  }
  &.DRAW {
    .btns .box {
      letter-spacing: 3.31px;
      color: #ffffff;
      text-shadow: 0px 4px 0px rgba(255, 255, 255, 0.3960784314);
      text-transform: uppercase;
      left: -60px;
      z-index: 100000;
      top: -15px;
    }
    p {
      span {
        color: #b1d5a0;
      }
    }
  }
  .whiteLine {
    position: absolute;
    height: 3px;
    background: white;
    width: calc(100% - 6px);
    top: 0px;
    left: 3px;
  }

  .whiteLine:after {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    background: white;
    left: -3px;
    top: 3px;
  }

  .whiteLine:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    background: white;
    right: -3px;
    top: 3px;
  }
  .btns {
    position: relative;
    .box {
      position: relative;
      position: absolute;
      z-index: 1000;
      letter-spacing: 3.31px;
      color: #ffffff;
      text-shadow: 0px 4px 0px rgba(255, 255, 255, 0.3960784314);
      text-transform: uppercase;
      left: -60px;
      z-index: 100000;
      top: -15px;
      img {
        width: 225px;
        height: auto;
      }
    }
    p {
      margin-top: 159px;
      letter-spacing: 1.69px;
      font-size: 22px;
      line-height: 26px;
      width: 165px;
    }
  }

  //   .images .btns .box:first-child {
  //     right: 52px;
  //     top: 0px;
  // 	width: 153px;
  //   }
  //   .images .btns .box:nth-of-type(2) {
  //     top: 50px;
  //     left: 6px;
  //     span.topCornerLeftDots:after {
  //       background: #b1d5a0;
  //     }
  //   }
}

.cardsBg {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.fade-in {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0;
  }
}

.cardsSlide .cardsBg {
  z-index: 1000;
  position: relative;
}

img.cardBackground {
  position: absolute;
  right: -63px;
}

.cardsSlide {
  min-width: 164px;
}
