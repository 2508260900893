.navbar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 20px;
  width: 100%;
  left: 0;
  padding: 0 20px;
  z-index: 1000;
  @media (max-width: 530px) {
    flex-flow: wrap;
  }
  .user-info {
    display: flex;
    align-items: center;
    @media (max-width: 530px) {
      //   width: 100%;
      //   margin-bottom: 10px;
    }
    .navProfileBtn {
      display: flex;
      align-items: center;
      padding: 0;

      @media (max-width: 419px) {
        &.home {
          max-width: initial;
        }
        max-width: 130px;
      }
    }
    .userName {
      position: relative;
      top: 1px;
      .name {
        min-height: 16px;
      }
      p {
        margin: 0;
        padding-left: 10px;
        &:first-child {
          letter-spacing: 1.23px;
          color: #707070;
          font-size: 16px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @media (max-width: 410px) {
            width: 85px;
          }
        }
        &:last-child {
          letter-spacing: 0.92px;
          color: #ffffff;
          font-size: 12px;
          line-height: 22px;
          text-align: left;
        }
      }
    }
  }
  .closeBtn {
    @media (max-width: 350px) {
      max-width: 90px;
    }
  }
  .userProfile {
    width: 42px;
    height: 42px;
    object-fit: cover;
    display: block;
  }
  .navCards {
    padding: 0px 10px 4px 10px;
    img {
      height: 26px;
      width: 20px;
      position: relative;
      top: 3px;
    }
    // span.extraShadow {
    //   display: none;
    // }
    span.bottomCornerLeftDots:before {
      background: #b7b18a;
    }
    span.bottomCorneRightDots:before {
      background: #b7b18a;
    }
  }
  //   .user-info .navProfileBtn .extraShadow {
  //     display: none;
  //   }
}

.walletIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: absolute;
    top: -13px;
  }
  svg:last-child {
    right: 3px;
    top: -6px;
  }
}

.secondaryColor.connectButton {
  margin-left: 1px;
  svg g {
    stroke: #b7b18a;
  }
  &:hover {
    .walletIcon svg {
      g {
        stroke: #b7b18a;
      }
    }
  }
}

button.primary-button-styles.raise.closeBtn.relative.primary-button {
  padding: 0;
}
.connect-button {
  display: flex;
  align-items: center;
}

.avatar {
  position: relative;
}
.avatar .badge {
  position: absolute;
  width: 14px;
  top: 5px;
  right: -10px;
}

.guideTourBtn {
  position: fixed;
  top: 70px;
}
