.hostGame {
    position: relative;
    padding: 20px;
    max-width: 397px;
    margin: auto;
    border: 3px solid #ABABAC;
    margin-top: 6rem;
}

.hostInput {
    display: flex;
    align-items: center;
    border-radius: 0;
    background-color: black;
    padding-top: 0.40rem;
    padding-bottom: 0.40rem;
    border: 3px solid #717070;
    justify-content: space-between;
    padding-left: 10px;
    color: #ababac;
    font-size: 22px;
    line-height: 22px;
}

.hostInput h2 {
    margin: 0;
    color: #f0ebc0;
    font-size: 22px;
    padding-right: 10px;
}

.hostInput p {
    margin: 0;
}

.hostInput h2 input {
    padding-right: 10px;
    text-align: right;
    color: #f0ebc0;
    background: black;
    border: none;
    box-shadow: none;
    font-size: 22px;
    width: 130px;
    font-family: "Pixel Millennium";
	@media (max-width: 430px) {
		width: 120px;
	}
}

.hostInputTitle {
    text-align: center;
    margin-bottom: 12px;
    margin-top: 24px;
}