.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  border: 3px solid #717070;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000 0% 0% no-repeat padding-box;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider .sliderButton {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  bottom: 6px;
  background-color: #1a1a1a;
  transition: 0.4s;
  border: 3px solid #717070;
}

input:checked + .slider {
  background: #f0ebc0 0% 0% no-repeat padding-box;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider .sliderButton {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background: #f0ebc0 0% 0% no-repeat padding-box;
  border: 3px solid #b7b18a;
}

input:checked + .slider .sliderButton span.bottomCorneRightDots:after {
  background: #f0ebc0;
}

input:checked + .slider .sliderButton span.bottomCornerLeftDots:after {
  background: #f0ebc0;
}

/* Not selected extra shadow */

.sliderButton span.extraShadow {
  background: #717070;
}

.sliderButton span.extraShadow:after {
  background: #717070;
}

.sliderButton span.extraShadow:before {
  background: #717070;
}

/* end of not selected extra shadow */

/* selected extra shadow */

input:checked + .slider .sliderButton span.extraShadow {
  background: #b7b18a;
}

input:checked + .slider .sliderButton span.extraShadow:after {
  background: #b7b18a;
}

input:checked + .slider .sliderButton span.extraShadow:before {
  background: #b7b18a;
}

/* end of selected extra shadow */

.joinRoomContainer .sliderButton span.topCornerLeftDots:before {
  background: #717070;
}

.joinRoomContainer .sliderButton span.topCornerRightDots:before {
  background: #717070;
}

.slider .sliderButton span.topCornerLeftDots:before {
  background: #717070;
}

.toggleButton {
  text-align: center;
  color: #707070;
  margin-bottom: 2rem;
  margin-top: 2rem;
  &.disabled {
    label {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
