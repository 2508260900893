.pageLinks {
  text-align: center;
  margin-top: 40px;
  a {
    display: block;
    max-width: 244px;
    margin: 0 auto 0.5rem;
  }
  .nav-link {
    display: block;
    max-width: 244px;
    margin: 0 auto 0.5rem;
    position: relative;
    .popover {
      display: none;
      width: 250px;
      white-space: break-spaces;
      top: -163px;
      span {
        color: #82b5d9 !important;
        font-weight: bold;
        cursor: pointer;
      }
    }
    &:hover {
      .popover {
        display: block;
      }
    }
  }
}

.leaderBoard {
  border: 3px solid #82b5d9;
  position: relative;
  padding: 20px;
  max-width: 768px;
  margin: 5rem auto 0;
}

.header {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin-bottom: 2rem;
  .title {
    p {
      letter-spacing: 3.31px;
      font-size: 43px;
      line-height: 39px;
      text-shadow: 0px 4px 0px #ffffff65;
      color: white;
      text-transform: uppercase;
      margin: 0;
      span {
        display: block;
        font-size: 15px;
        text-shadow: none;
        letter-spacing: 1.16px;
        padding-top: 0.5rem;
        color: #f0ebc0;
      }
    }
  }
}

.leftArrow svg {
  transform: rotate(180deg);
}

.leader-board-body {
  position: relative;
  padding-right: 2px;
}

.leader-board-content {
  display: flex;
  margin-bottom: 33px;
  position: relative;
  .avatar {
    width: 50px;
    margin-left: 5px;
    @media (max-width: 420px) {
      width: 40px;
    }
    img {
      height: 50px;
      width: 50px;
      -o-object-fit: cover;
      object-fit: cover;
      display: block;
      @media (max-width: 420px) {
        height: 40px;
        object-fit: cover;
        width: 40px;
      }
    }
  }
  .userName {
    width: calc(100% - 265px);
    background: #2b2b2b;
    display: flex;
    align-items: center;
    font-size: 23px;
    color: white;
    padding: 4px 15px;
    @media (max-width: 420px) {
      width: calc(100% - 155px);
      font-size: 14px;
    }
    .leader-board-user-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 440px;
    }
  }
  .rank {
    width: 50px;
    @media (max-width: 420px) {
      width: 40px;
    }
    img {
      @media (max-width: 420px) {
        width: 40px;
      }
    }
    .rankImage {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 50px;
      @media (max-width: 420px) {
        width: 40px;
      }
      span {
        position: absolute;
        color: black;
      }
    }
  }
  .totalWins {
    background: #2b2b2b;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 10px;
    width: 160px;
    font-size: 14px;
    @media (max-width: 420px) {
      min-width: 52px;
    }
    span {
      @media (max-width: 420px) {
        display: none;
      }
    }
  }
  .forth {
    position: absolute;
    bottom: -17px;
    right: 0;
    background: #2b2b2b;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    @media (max-width: 420px) {
      font-size: 13px;
    }
    svg {
      margin-right: 10px;
    }
  }
  .totalPrice {
    background: #1a1a1a;
    padding: 2px 15px;
    min-width: 140px;
    text-align: right;
  }
}
.leaderBoard span.topCornerLeftDots:before {
  background-color: #82b5d9;
}

.leaderBoard span.topCornerRightDots:before {
  background-color: #82b5d9;
}

.leaderBoard span.bottomCornerLeftDots:before {
  background-color: #82b5d9;
}

.leaderBoard span.bottomCorneRightDots:before {
  background-color: #82b5d9;
}

.totalWins span {
  display: inline-block;
  margin-left: 4px;
}

.modalDetails .cardIconsInfo {
  flex-flow: wrap;
  justify-content: space-around;
  margin-top: 20px;
  ul {
    width: 46%;
  }
}

.totalCards {
  color: #a7a7a7;
}

.opConditionBody {
  span {
    display: inline-block;
    margin: 0 5px;
  }
  .card1 {
    color: #f6e39c;
  }
  .card2 {
    color: #735f4b;
  }
  .card3 {
    color: #82b5d9;
  }
}
