.awaitingOpponent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 767px;
    margin: 5rem auto 0;
    padding: 0 10px;
}

.playerBorderStyles {
    position: relative;
    border: 3px solid #717070;
}

.description {
    text-align: center;
    max-width: 767px;
    margin: 1rem auto 3rem;
}

.cancelRoom {
    text-align: center;
    margin-top: 5rem;
}

.playerTwo {
    position: relative;
}

p.playerV2 {
    position: absolute;
    width: 100%;
    text-align: center;
}

.text-center {
    text-align: center;
}