.trophy-content {
    display: flex;
    flex-direction: column;
    gap: 16px; /* Adjust spacing between items */
    padding: 20px; /* Add some padding around the container */
  }
  
.trophy-item {
display: flex;
justify-content: space-between; /* Align label and value */
font-size: 16px;
color: #fff; /* Assuming you want white text */
background-color: #333; /* Background color for each item */
padding: 10px;
border-radius: 8px; /* Rounded corners */
}

.trophy-label {
font-weight: bold;
color: #f0e68c; /* Label color (light yellow, for example) */
}
