.allowanceModal {
	p {
		margin: 0;
	}
	.percents {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #707070;
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}
	
	.available {
		text-align: center;
		color: #717070;
		margin-bottom: 1rem;
	}
	
	 p.allowanceFort {
		color: white;
		letter-spacing: 1.69px;
		font-size: 22px;
		margin-top: 0.5rem;
	}
}

.allowanceSelected {
    background: #000000 0% 0% no-repeat padding-box;
    border: 3px solid #717070;
    width: 244px;
    display: flex;
    justify-content: space-between;
    padding: 7px 10px;
    margin: 0 auto 32px;
    letter-spacing: 1.69px;
    color: #F0EBC0;
    font-size: 22px;
    line-height: 22px;
    margin-top: 1rem;
}

.setAllowance {
	display: flex;
    flex-flow: wrap;
    justify-content: space-around;
	button {
		&:first-child {
			margin-bottom: 1rem;
		}
	}
}