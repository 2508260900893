
.profileTitle {
    letter-spacing: 1.23px;
    color: #ABABAC;
    font-size: 16px;
    text-align: center;
}

.profileUserName {
	text-align: center;
	margin-top: 3rem;
	> div {
		text-align: center;
		font-size: 22px;
		height: 42px;
		line-height: 42px;
		letter-spacing: 1.5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	div {
		p {
			display: inline-block;
			vertical-align: text-bottom;
			margin: 0;
			margin-right: 9px;
		}
	}
}

.profileName {
    color: #ABABAC;
}

.userAvatar {
    text-align: center;
	img {
		object-fit: cover;
		object-position: center;
		width: 244px;
		height: 244px;
	}
}

.uploadUserPhotoButton {
    text-align: center;
    padding-top: 8px;
    width: 244px;
    margin: 1rem auto 0;
    position: relative;
    display: block;
	input {
		position: absolute;
		opacity: 0;
		left: 0;
	}
}

.profileUserName button {
    background: transparent;
    box-shadow: none;
    border: none;
    color: #ABABAC;
	width: 16px;
	font-size: 20px;
	font-family: 'Pixel Millennium';
	cursor: pointer;
}


.inputStyle {
    border-radius: 4px;
    background: #000000 0% 0% no-repeat padding-box;
    border: 3px solid #717070;
    padding: 5px;
    letter-spacing: 1.69px;
    color: #717070;
    font-size: 22px;
    line-height: 22px;
    min-height: 42px;
	text-align: center;
}