.congratulations {
    padding: 0;
    text-align: center;
	span.position {
		letter-spacing: 4.62px;
		color: #F0EBC0;
		text-shadow: 0px 8px 0px #FFFFFF40;
		font-size: 60px;
		margin-top: 1rem;
		display: block;
	}
	p.descriptionModal {
		margin-bottom: 2rem;
		padding: 0 20px;
	}
	
	.buttons {
		margin-bottom: 1rem;
	}
}
p.congratulationsTitle {
    position: relative;
    background: #C3736C;
    width: 100%;
    letter-spacing: 3.31px;
    color: #FFFFFF;
    text-shadow: 0px 4px 0px rgba(255, 255, 255, 0.3960784314);
    border: 4px solid #E29791;
    padding: 3px;
    margin-top: 4rem;
    font-size: 38px;
    text-align: center;
}