.progress-bar-container {
  width: 100%;
  margin: 60px 0 20px 0;

  .progress-bar {
    position: relative;
    height: 20px;
    background-color: #000000;
    border-radius: 10px;
    border: 3px solid #717070;
    .progress {
      height: 100%;
      background-color: #efeabf;
      transition: width 0.3s ease;
      border-radius: 10px;
    }

    .icon {
      position: absolute;
      top: -40px;
      transform: translateX(-50%);
      font-size: 20px;
      color: white;
      opacity: 1;
      &.last {
        top: -49px !important;
      }
      span {
        display: block;
        text-align: center;
        line-height: 10px;
        letter-spacing: 0.16px;
        color: #707070;
        font-size: 16px;
      }
      &.icon-150 i {
        color: gold;
      }
      &.icon-250 i {
        color: red;
      }
    }
    /* Circle representing the currentValue */
    .current-value-circle {
      position: absolute;
      top: 0px;
      width: 10px;
      height: 25px;
      background-color: #efeabf;
      border: 3px solid #efeabf;
      text-align: center;
    }
  }

  .progress-info {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
  }
}

.icon img {
  width: 15px;
}

.startIcon {
  position: absolute;
  top: -37px;
  transform: translateX(-50%);
  font-size: 20px;
  color: white;
  opacity: 1;
}

.startIcon img {
  width: 15px;
}

.startIcon span {
  display: block;
  text-align: center;
  line-height: 10px;
  letter-spacing: 0.16px;
  color: #707070;
  font-size: 16px;
}

.current-value-circle span {
  position: relative;
  top: 26px;
  left: -7px;
  color: #efeabf;
}
