.playerCards {
  margin-top: 3rem;
  .cardBox {
    display: flex;
    .card {
      width: 110px;
      background: url("../../../../assets/images/cards/cardBg.png");
      background-size: 100% 100%;
      margin: 0 5px;
      height: 196px;
      position: relative;
      @media (max-width: 390px) {
        width: 85px;
        height: 152px;
      }
      img {
        z-index: 1;
        position: relative;
        height: 99%;
      }
      svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    &:first-child {
      margin-bottom: 1rem;
    }
  }
}

img.comboCard {
  position: absolute !important;
  right: -15px;
  bottom: -20px;
  width: 136.5px;
  height: 235px !important;
  z-index: 10000 !important;
  @media (max-width: 390px) {
    height: 179px !important;
    right: -11px;
    width: 106.5px;
    z-index: 10000 !important;
    bottom: -13px;
  }
}

img.burnCard {
  position: absolute !important;
  right: -15px;
  bottom: -20px;
  width: 130%;
  height: 235px !important;
  z-index: 1000000 !important;
  @media (max-width: 390px) {
    height: 175px !important;
    right: -11px;
    width: 106.5px;
    z-index: 100000 !important;
    bottom: -13px;
  }
}

img.comboNumber {
  position: absolute !important;
  right: -15px;
  bottom: -25px;
  width: 130%;
  height: 240px !important;
  @media (max-width: 390px) {
    position: absolute !important;
    right: 0;
    bottom: 10px;
    width: 100%;
    height: 100% !important;
  }
}

.cardOpened .cardsList .cards .card img:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

.cardOpened .cardsList .cards .card img {
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.gameBoardContainer .logo {
  margin-top: 0px;
}

.cardBox.joinerCards {
  flex-direction: row-reverse;
  margin-top: 60px;
}

.forceOpponentHand {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  position: absolute;
  top: 42px;
  left: 8px;
}

.forceOpponentHand button.primary-button-styles.raise.relative.primary-button {
  font-size: 15px;
  padding: 0;
  max-width: 280px !important;
  letter-spacing: 0.1em;
  width: 226px !important;
  @media (max-width: 390px) {
    width: 166px !important;
  }
}

@keyframes cardJump {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-250px) scale(2);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.playingCard {
  position: relative;
  animation: cardJump 0.8s ease-in-out;
  transition: transform 0.3s ease;
}
