.leader-board-title {
    letter-spacing: 3.31px;
    font-size: 43px;
    line-height: 39px;
    text-shadow: 0px 4px 0px #FFFFFF65;
	margin: 0;
}

.closeModal {
    position: absolute;
    font-size: 40px;
    right: 20px;
    top: 0px;
    cursor: pointer;
}

.congratulations .description {
    padding-top: 1.25rem;
    padding-bottom: 1.75rem;
    color: #707070;
    letter-spacing: 1.69px;
    font-size: 22px;
    line-height: 26px;
	margin: 0;
}
.buttons button {margin-bottom: 10px;}