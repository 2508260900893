


.playerInfo {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 345px;
    margin-bottom: 30px;
    margin-top: 30px;
	position: relative;
	.avatar {
		position: relative;
		border: 3px solid #717070;
		width: 50px;
		height: 50px;
		margin-right: 3px;
		img {
			width: 44px;
			height: 44px;
			object-fit: cover;
		}
	}
	.player {
		padding: 8px 10px 0;
		border: 3px solid #717070;
		height: 50px;
		margin-right: 3px;
		position: relative;
		background: black;
		label {
			display: block;
			letter-spacing: 1.23px;
			color: #707070;
			text-transform: uppercase;
			font-size: 16px;
			line-height: 14px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 144px;
			@media (max-width: 390px) {
				width: 84px;
			}
		}
		span {
			letter-spacing: 0.92px;
			color: #FFFFFF;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 14px;
		}
	}
	.timer {
		height: 50px;
		display: flex;
		align-items: center;
		padding: 10px;
		border: 3px solid #717070;
		position: relative;
		background: black;
		min-width: 119px;
		svg {
			fill: #707070;
			margin-right: 10px;
		}
		span {
			display: block;
			width: 14px;
			font-size: 18px;
		}
	}
	.union {
		position: absolute;
		border: 3px solid #6A6A6A;
		right: 0;
		bottom: -28px;
		background: black;
		padding: 5px 10px 3px;
		svg {
			fill: #6A6A6A;
			margin: 0 2px;
		}
	}
}

.playerInfo .active.timer {
    border-color: #F0EBC0;
    color: #F0EBC0;
}

.playerInfo .active.timer svg {
    fill: #F0EBC0;
}

.playerInfo .active.union {
    border-color: #F0EBC0;
}