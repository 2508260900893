.hostGameDescription {
  line-height: 1.5rem;
  letter-spacing: 1.23px;
  color: #ababac;
  text-align: center;
  margin-bottom: 1rem;
}

.hostGame {
  position: relative;
  padding: 20px;
  max-width: 397px;
  margin: auto;
  border: 3px solid #ababac;
  margin-top: 6rem;
}
.rarityInPercentages span {
  display: inline-block;
  margin: 0 5px;
}

.cardsInfo {
  .error {
    color: red;
  }
}
