.cardOpened {
    position: fixed;
    top: 0;
    right: -355px;
    width: 370px;
    height: 100%;
    border-left: 3px solid #717070;
    background: black;
	transition: right 0.5s ease; 
	z-index: 1000000;
	// background-image: url('../../../../assets/images/backgrounds/gameBoardCardsBG.png');
    @media (max-width: 430px) {
		right: -315px;
    	width: 330px;
	}
	.notYourTurn {
		display: none;
	}
	&.inactive {
		.notYourTurn {
			position: absolute;
			display: flex!important;
			height: 100%;
			align-items: center;
			justify-content: center;
			width: 100%;
			color: #ececec;
			display: none;
			font-size: 30px;
			text-transform: uppercase;
			text-shadow: 0px 4px 0px #FFFFFF65;
			background: rgb(0 0 0 / 77%);
		}
	}
	&.slide-right {
		// transform: translateX(0); // Adjust as needed for the slide distance
		right: 0
	  }
	.cardsOpenIcon {
		position: absolute;
		display: flex;
		align-items: center;
		height: 100%;
		img {
			position: relative;
			left: -47px;
			width: 48px;
			height: 60px;
			padding: 0;
			cursor: pointer;
			border-right: 3px solid black;
			border: 0;
		}
	}
	.cardsList {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		margin: 0;
		max-width: 370px;
		.cards {
		  display: grid;
		  grid-template-columns: repeat(3, 1fr); // 3 columns
		  grid-gap: 20px; // Adjust spacing as needed
		  place-items: center;
		  max-width: 600px; // Adjust as needed to control the grid's total width
		  overflow: auto;
		  max-height: 100vh;
		  padding: 30px 0;
		  // Center the grid within its container
		  justify-content: center;
	
		  .card {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100px;
			height: 182px;
			@media (max-width: 430px) {
				width: 80px;
			}
			img {
			  width: 100%; // Adjust image to fit the card
			  height: auto;
			}
			//Aegix added
			&.disabled {
				opacity: 0.2; /* Grey out the card */
				pointer-events: none; /* Disable interaction */
			}  
		
			}
		}
	  }
}

.cardOpened.slide-right.inacitve .cardsList:after {
    content: "";
    position: absolute;
    background: #0000008a;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
}
