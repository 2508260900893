.mutantResearch {
  max-width: 430px;
  margin: auto;
  padding: 4rem 20px 70px 20px;
  text-align: center;
  position: relative;
  .icon {
    position: relative;
    width: 81px;
    height: 81px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .icon svg:first-child {
    position: absolute;
    width: 81px;
    height: 81px;
  }

  .icon svg:last-child {
    z-index: 10;
    position: relative;
    top: -3px;
  }
  .mainPhoto {
    margin-top: -110px;
    img {
      width: 100%;
    }
  }
  .rewardLevel h3 {
    letter-spacing: 1.23px;
    color: #707070;
    font-size: 16px;
    line-height: 16px;
    span {
      display: block;
      letter-spacing: 0.92px;
      color: #ababac;
      font-size: 12px;
      line-height: 12px;
      margin-top: 8px;
    }
  }
  .stakeAll {
    display: flex;
    width: 100%;
    /* gap: 1rem; */
    margin-top: -50px;
    padding: 0;
    /* flex-flow: wrap; */
    /* justify-content: space-between; */
    overflow: auto;
  }

  .stakeAll .box {
    width: 200px;
    margin: 0 10px;
    min-width: 139px;
    &:first-child {
      margin-left: 0;
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .item .toggleButton {
    margin: 0;
  }

  .total {
    margin-top: 3rem;
    flex-flow: wrap;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    .box {
      position: relative;
      background: #1a1a1a 0% 0% no-repeat padding-box;
      border: 3px solid #82b5d9;
      padding: 15px 5px;
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
      align-items: stretch;
      h5 {
        letter-spacing: 1.23px;
        color: #707070;
        font-size: 16px;
        margin: 0;
        width: 100%;
      }
      h3 {
        letter-spacing: 3.31px;
        color: #ffffff;
        text-shadow: 0px 3px 0px rgba(255, 255, 255, 0.3529411765);
        font-size: 40px;
        line-height: 32px;
        margin: 0;
        margin-top: 15px;
        width: 100%;
      }
    }
  }

  .mutantFooter {
    position: absolute;
    bottom: -160px;
  }

  .mutantFooter svg {
    max-width: 100%;
    margin-top: 4rem;
  }
}

.rewardProgressBar {
  .level {
    position: relative;
    padding: 6px 9px;
    border: 3px solid #717070;
    background: black;
  }

  .progressBar {
    background: #efeabf 0% 0% no-repeat padding-box;
    height: 11px;
    position: relative;
    span.topCornerLeftDots:before {
      background-color: black;
    }
    span.topCornerLeftDots:after {
      background-color: black;
    }
    span.topCornerRightDots:before {
      background-color: black;
    }
    span.topCornerRightDots:after {
      background-color: black;
    }
    span.bottomCornerLeftDots:before {
      background-color: black;
    }
    span.bottomCornerLeftDots:after {
      background-color: black;
    }
    span.bottomCorneRightDots:before {
      background-color: black;
    }
    span.bottomCorneRightDots:after {
      background-color: black;
    }
  }
  .status {
    display: flex;
    justify-content: space-between;
    text-align: left;
    letter-spacing: 1.23px;
    color: #ababac;
    font-size: 16px;
    margin-top: 5px;
  }

  .status label span {
    display: block;
    letter-spacing: 0.92px;
    color: #ababac;
    font-size: 12px;
  }

  .status label:last-child {
    text-align: right;
  }
}

.mutantResearch h1 {
  max-width: 220px;
  text-align: center;
  width: 100%;
  margin: 0 auto 20px;
}

.mutantDescription {
  letter-spacing: 0.22px;
  color: #ababac;
  font-size: 22px;
  line-height: 22px;
}

.totalStaked {
  position: relative;
  background: #1a1a1a 0% 0% no-repeat padding-box;
  border: 3px solid #717070;
  max-width: 326px;
  margin: 4rem auto 1rem;
  padding: 20px 5px 5px;
}

.totalStaked h4 {
  letter-spacing: 1.62px;
  color: #717070;
  text-transform: uppercase;
  font-size: 21px;
  line-height: 22px;
  margin: 0;
}

h4.sfort {
  letter-spacing: 1.39px;
  color: #b7b18a;
  font-size: 18px;
  text-align: center;
  margin-bottom: 1.5rem;
}

h4.sfort span {
  display: inline-block;
  margin: 0 17px;
}

.mtntSFort {
  background: #000000 0% 0% no-repeat padding-box;
  border: 3px solid #717070;
  display: flex;
  justify-content: space-between;
  max-width: 242px;
  margin: auto;
  padding: 10px 15px;
}

.mtntSFort p {
  margin: 0;
  color: #707070;
}

.mtntSFort p span {
  color: white;
  display: inline-block;
  margin-right: 9px;
}

.mutantResearch .allowanceAdd {
  text-align: left;
}
