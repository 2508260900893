@font-face {
  font-family: "Pixel Millennium";
  src: url("./assets/fonts/PixelMillennium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: "Pixel Millennium";
  color: #ababac;
}

input {
  font-family: "Pixel Millennium";
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.relative {
  position: relative;
}

.logo {
  text-align: center;
  color: white;
  margin-top: 5rem;
  img {
    max-width: 200px;
  }
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.hover\:-translate-y-2:hover {
  transform: translate(0px, -10px);
}

p.cardsRatioInfoCircles span {
  color: #82b5d9;
}

p.cardsRatioInfoSquares span {
  color: #f0ebc0;
}
