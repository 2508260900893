.profile-info {
  max-width: 430px;
  margin: auto;
  display: flex;
  @media (max-width: 450px) {
    max-width: 280px;
  }
}

.profile-info .user img {
  width: 150px;
  @media (max-width: 450px) {
    max-width: 110px;
  }
}

.profile-info .user {
  margin-right: 20px;
}

.user-info-box .box {
  position: relative;
  margin-bottom: 10px;
}

.user-info-box .box img {
  max-width: 100%;
}

.user-info-box .box label {
  display: block;
  text-align: center;
}

.user-info-box .box span {
  position: absolute;
  left: 0;
  right: -40px;
  margin: auto;
  top: 22px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #ffffff;
  text-shadow: 0px 3px 0px rgba(255, 255, 255, 0.3529411765);
  font-size: 33px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 5px;
  @media (max-width: 450px) {
    right: -20px;
    margin: auto;
    top: 18px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #ffffff;
    text-shadow: 0px 3px 0px rgba(255, 255, 255, 0.3529411765);
    font-size: 22px;
  }
}

.credit-balance {
  max-width: 146px;
  margin: auto;
  text-align: center;
  margin-top: 30px;
  label {
    margin-bottom: 5px;
    display: block;
  }
  span {
    width: 146px;
    background-image: url("../../assets//images//myscrapshop/priceBg.png");
    margin: auto;
    height: 50px;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    color: #ffffff;
    text-shadow: 0px 3px 0px rgba(255, 255, 255, 0.3529411765);
    font-size: 33px;
    line-height: 38px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
}

.scrapShopItems {
  max-width: 350px;
  margin: auto;
  h3 {
    letter-spacing: 1.31px;
    color: #ffffff;
    text-shadow: 0px 3px 0px rgba(255, 255, 255, 0.3529411765);
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-top: 60px;
  }
  p {
    text-align: center;
    margin: 0 0 20px 0;
  }
  .container {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    .top {
      text-align: center;
      background-image: url("../../assets//images//myscrapshop/Union.png");
      background-size: 100% 100%;
      padding: 10px;
      img {
        height: 110px;
      }
      &.miniPack img {
        position: relative;
        left: 8px;
      }
    }
    .middle {
      background-image: url("../../assets//images//myscrapshop/popUp.png");
      background-size: 100% 100%;
      padding: 10px;
      margin-top: -4px;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      color: #000000;
    }
    .bottom {
      background-image: url("../../assets//images//myscrapshop/buy.png");
      background-size: 100% 100%;
      padding: 10px;
      text-align: center;
      font-size: 25px;
      cursor: pointer;
    }
  }
}

.storageList {
  display: flex;
  overflow: hidden;
}

.storage-item {
  flex-shrink: 0; /* Prevent items from shrinking */
  width: 100px; /* Adjust to your desired item width */
  text-align: center;
  margin-right: 10px;
}

.luckySpin {
  max-width: 353px;
  margin: auto;
  display: flex;
  padding: 15px 20px;
  margin-top: 20px;
  background-image: url("../../assets/images//myscrapshop/luckySpinBorder.png");
  background-size: 100% 100%;
  .image {
    img {
      max-width: 100%;
    }
  }
}

button.spinButton {
  background-image: url("../../assets/images/myscrapshop/buy.png");
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  font-family: "Pixel Millennium";
  color: #b7b18a;
  background-size: 100% 100%;
  padding: 6px 30px;
  background-color: transparent;
  border: none;
}

.storage {
  max-width: 400px;
  margin: auto;
  position: relative;
}
.storage {
  max-width: 400px;
  margin: auto;
  position: relative;
  h3 {
    letter-spacing: 1.31px;
    color: #ffffff;
    text-shadow: 0px 3px 0px rgba(255, 255, 255, 0.3529411765);
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-top: 60px;
  }
  p {
    text-align: center;
    margin: 0 0 20px 0;
  }
}

.storage button:first-child {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.listContainer {
  position: relative;
  button.leftArrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 30px;
  }

  button.rightArrow {
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    height: 30px;
    margin: auto;
  }
}
