.cardIconsInfo {
  ul {
    li {
      position: relative;
    }
  }
  .popover {
    display: none;
  }
}

.cardIconsInfo ul li:hover .popover {
  display: block;
}
