.hostGameInputRangeSlider input[type="range"] {
    background-color: transparent;
    margin: 0;
    height: 31px;
}

.hostGameInputRangeSlider .inputBox {
    height: 31px;
    background: black;
	position: relative;
}


input[type=range] {
	height: 38px;
	-webkit-appearance: none;
	margin: 10px 0;
	width: 100%;
  }
  input[type=range]:focus {
	outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 10px;
	cursor: pointer;
	animate: 0.2s;
	box-shadow: 1px 1px 1px transparent;
	background: transparent;
	border-radius: 5px;
	border: 1px solid transparent;
  }
  input[type=range]::-webkit-slider-thumb {
	box-shadow: 1px 1px 1px #000000;
	border: 2px solid #717070;
	height: 20px;
	width: 20px;
	border-radius: 5px;
	background: #717070;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -6px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
	background: transparent;
  }
  input[type=range]::-moz-range-track {
	width: 100%;
	height: 10px;
	cursor: pointer;
	animate: 0.2s;
	box-shadow: 1px 1px 1px #000000;
	background: #3071A9;
	border-radius: 5px;
	border: 1px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
	box-shadow: 1px 1px 1px #000000;
	border: 1px solid #000000;
	height: 30px;
	width: 15px;
	border-radius: 5px;
	background: #FFFFFF;
	cursor: pointer;
  }
  input[type=range]::-ms-track {
	width: 100%;
	height: 10px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	color: transparent;
  }
  input[type=range]::-ms-fill-lower {
	background: #3071A9;
	border: 1px solid #000000;
	border-radius: 10px;
	box-shadow: 1px 1px 1px #000000;
  }
  input[type=range]::-ms-fill-upper {
	background: #3071A9;
	border: 1px solid #000000;
	border-radius: 10px;
	box-shadow: 1px 1px 1px #000000;
  }
  input[type=range]::-ms-thumb {
	margin-top: 1px;
	box-shadow: 1px 1px 1px #000000;
	border: 1px solid #000000;
	height: 30px;
	width: 15px;
	border-radius: 5px;
	background: #FFFFFF;
	cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
	background: #3071A9;
  }
  input[type=range]:focus::-ms-fill-upper {
	background: #3071A9;
  }
  
  .percents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #707070;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.hostGameInputRangeSlider span.topCornerLeftDots:before {
    background: #1A1A1A;
}

.hostGameInputRangeSlider span.topCornerRightDots:before {
	background: #1A1A1A;
}

.hostGameInputRangeSlider span.bottomCornerLeftDots:before {
	background: #1A1A1A;
}

.hostGameInputRangeSlider span.bottomCorneRightDots:before {
	background: #1A1A1A;
}

.hostGameInputRangeSlider .box {
    cursor: pointer;
}