.joinGameContainer {
  position: relative;
  padding: 20px;
  max-width: 397px;
  margin: auto;
  border: 3px solid #ababac;
  margin-top: 5rem;
  .cardIconsInfo {
    flex-flow: wrap;
    justify-content: center;
  }
}

.subTitle {
  letter-spacing: 1.16px;
  color: #f0ebc0;
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
  line-height: 22px;
}

.joinGameDescription {
  letter-spacing: 1.23px;
  color: #ababac;
  text-align: center;
  margin-bottom: 1rem;
}

.listItem {
  display: flex;
  position: relative;
  background: #2b2b2b;
  align-items: center;
  margin-bottom: 37px;
  .avatar {
    margin-right: 20px;
    position: relative;
    img {
      border-radius: 0;
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
  .name {
    h3 {
      letter-spacing: 0px;
      color: #ababac;
      text-transform: uppercase;
      display: block;
      font-size: 22px;
      line-height: 13px;
      margin: 0;
      margin-bottom: 6px;
    }
    span {
      letter-spacing: 0px;
      color: #f0ebc0;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 13px;
    }
  }
  .fee {
    position: absolute;
    right: 0;
    background: #1a1a1a;
    bottom: -30px;
    border: 3px solid #2b2b2b;
    padding: 5.5px 6px;
    min-width: 114px;
    display: flex;
    justify-content: space-between;
    span {
      &:first-child {
        color: #ababac;
        font-size: 15px;
        line-height: 13px;
      }
      &:last-child {
        color: #f0ebc0;
        font-size: 15px;
        line-height: 13px;
      }
    }
  }
  .joinButton {
    position: absolute;
    right: 0;
    top: 5px;
  }
}

.actionButtons {
  text-align: center;
  margin-top: 2rem;
}

.actionButtons button {
  display: block;
  margin: auto;
  margin-bottom: 10px;
}

.actionButtons a {
  text-decoration: none;
}

img.avatarImage {
  display: block;
}

.listItem .avatar img.badge {
  position: absolute;
  width: 20px;
  height: auto;
  right: -11px;
  top: 2px;
}

.roomOverlay {
  position: absolute;
  width: 100%;
  height: 102%;
  background: lch(0 0 0 / 0.36);
  top: -1px;
  left: 0;
}

// .roomOverlay {
// 	position: relative;
// 	width: 50px;
// 	height: 50px;
// 	background-color: rgba(0, 0, 0, 0.1);
//   }

.popover {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000000;
  border: 3px solid gray;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
  font-size: 14px;
  white-space: nowrap;
}

.popover::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.popover p {
  margin: 0;
}
