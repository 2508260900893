.yourCards {
  max-width: 564px;
  margin: auto;
  padding-top: 4rem;
  padding-bottom: 40px;
  .container {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    .card {
      cursor: pointer;
    }
  }
  .mainTitle {
    margin-top: 0;
  }
  .filterCards {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media (max-width: 430px) {
      flex-flow: wrap;
    }
  }

  .filterIcons {
    display: flex;
    gap: 1rem;
  }

  .filterIcons img {
    width: 37px;
    @media (max-width: 430px) {
      width: 29px;
    }
  }
  .totalCards {
    letter-spacing: 0.35px;
    color: #707070;
    text-transform: uppercase;
    text-align: right;
    margin: 0.5rem 0;
    margin: 1rem 0 1rem 0;
  }
  .filterIcons {
    > div {
      border: 2px solid transparent;
      border-radius: 50%;
      width: 41px;
      height: 41px;
      cursor: pointer;
      @media (max-width: 430px) {
        width: 23px;
      }
    }
    .selected_type {
      border-color: white;
    }
  }
}

p.cardNumber {
  text-align: center;
}

p.circulating.text-white {
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  margin-top: 4px;
  margin-bottom: 27px;
  letter-spacing: 0.92px;
  color: white;
}

.transferCard {
  max-width: 244px;
  margin: auto;
  margin-top: 2rem;
}

.picoCardBg {
  background-image: url("../../assets/images/cards/cardBg.png");
  background-size: 100% 100%;
  padding: 6px 10px;
  margin-top: 2rem;
}

.yourCardsList img.selected {
  border: 4px solid #f0ebc0;
  border-bottom: 10px solid #f0ebc0;
}

.selectWallet {
  display: flex;
  align-items: center;
  max-width: 188px;
  margin: auto;
}

.selectWallet .toggleButton {
  margin: 0 17px;
}

.deckBuilderBody {
  border: 3px solid gray;
  padding: 5px 10px;
}

.deckBuilderToggle {
  display: flex;
  .box {
    position: relative;
    background: #1a1a1a;
    border: 3px solid #717070;
    color: #707070;
    padding: 5px 10px;
    margin-right: 10px;
    top: 3px;
    cursor: pointer;
    &.active {
      color: #f0ebc0;
    }
  }
}

.deckBuilderToggle .box span.bottomCornerLeftDots {
  display: none;
}

.deckBuilderToggle .box span.bottomCorneRightDots {
  display: none;
}

.notEnoughCardsError {
  color: red;
}

.bulkSelect {
  display: flex;
  align-items: center;
  .toggleButton {
    margin: 0;
    margin-right: 10px;
  }
}

span.infinity {
  font-size: 30px;
  display: inline-block;
  vertical-align: middle;
}

.scrapTotalBtn {
  background-size: 100% 100%;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: black;
  margin: auto;
}

.selectAllCards {
  text-align: right;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 4px;
}

.selectAllCards input {
  margin: 0;
  margin-right: 10px;
  position: relative;
  top: 1px;
}
