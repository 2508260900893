.loader {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 70%);
    align-items: center;
    justify-content: center;
    z-index: 100000000;
	img {
		max-width: 250px;
	}
}